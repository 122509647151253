<template>
    <div class="admin-container" >
        <h1>Utilisateurs</h1>
        <div class="table-container">
            <div class="table-bar">
                <div class="table-search">
                    <input type="text" v-model="search" class="btn-search" placeholder="Chercher par email" />
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>Email</th>
                        <th>Ville</th>
                        <th>Pays</th>
                        <th>Fonction</th>
                        <th>Hôpital</th>
                        <th>Contact BACKBONE</th>
                        <th>Date création</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>   
                    <tr v-for="(item, idx) in usersList" :key="idx">
                        <td>{{ item.firstname }}</td>
                        <td>{{ item.lastname }}</td>
                        <td>{{ item.email}}</td>
                        <td>{{ item.city }}</td>
                        <td>{{ item.country }}</td>
                        <td>{{ item.user_function_name }}</td>
                        <td>{{ item.hospital }}</td>
                        <td>{{ item.backbone_contact }}</td>
                        <td class="date-display">{{displayDate(item.date_create)}}</td>
                        <td>
                            <div class="actions-container">
                                <label class="switch">
                                    <input type="checkbox" @click="changeState(item.user_state, item.user_id)" :class="{ active : item.user_state == 3 }">
                                    <span class="slider round"></span>
                                </label>
                                <div class="actions-btn delete-btn" @click="deleteModal(item.user_id)"></div>
                            </div>
                        </td>
                    </tr>                                   
                </tbody>
            </table>
        </div>
        <NotifError 
            :errorTxt="errorTxt" 
            v-if="errorTxt" 
            v-on:closeModal="errorTxt = null"
        >
        </NotifError>
        <ModalConfirm
            v-if="displayModal"
            contentModal="Etes vous sur de vouloir supprimer cet utilisateur ?<br /> Toutes les données liées à celui-ci seront également supprimées"
            :id="userId"
            v-on:hide="displayModal = false"
            v-on:confirm="confirmDelete"
        >
        </ModalConfirm>
    </div>
</template>

<script>
import axios from 'axios'
import store from './../../store'
import NotifError from './../../components/NotifError'
import ModalConfirm from './../../components/ModalConfirm'

export default {
    name : 'AdminUsers',
    data() {
        return {
            users : null,
            search  : null,
            errorTxt : null,
            displayModal : false,
            userId : null
        }
    },
    components :{
        NotifError,
        ModalConfirm
    },
    mounted() {
        let vm = this
        this.errorTxt = null

        axios
            .get(store.getters.getURL + "user/getUsers.php?token=" + this.$store.state.JWT_TOKEN )
            .then(function (response) { 
                if(response.data.state == 'success') {
                    vm.users= response.data.res
                }else {
                    if(response.data.authError) {
                        store.commit('logout')
                        vm.$router.push('/login')
                    }else {
                        vm.errorTxt = 'An error has occured: ' + response.data.retour
                    }
                }
            })
            .catch((err) => {
                this.errorTxt = 'An error has occured: ' +  err
            });
    },
    methods : {
        displayDate(item) {
            const date = new Date(item);
            const formattedDate = date.toLocaleString("fr-FR", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
            });

            return formattedDate;
        },
        changeState(state, user) {
            let newState = 2;
            if(state == 2) {
                newState = 3;
            }

            let params = {
                user : user,
                state : newState,
                token : store.state.JWT_TOKEN
            }

            let vm = this

            this.errorTxt = null

            axios
                .post(store.getters.getURL + "user/changeState.php", params )
                .then(function (response) { 
                    if(response.data.state == 'success') {
                        vm.users.find(e => e.user_id === user ).user_state = newState
                    }else {
                        if(response.data.authError) {
                            store.commit('logout')
                            vm.$router.push('/login')
                        }else {
                            vm.errorTxt = 'An error has occured: ' + response.data.retour
                        }
                    }
                })
                .catch((err) => {
                    this.errorTxt = 'An error has occured: ' +  err
                });

        },
        deleteModal(id) {
            this.userId = id
            this.displayModal = true
        },
        confirmDelete(item) {

            let params = {
                id : item.id,
                token : store.state.JWT_TOKEN
            }

            let vm = this

            axios
                .post(store.getters.getURL + "user/delete.php", params )
                .then(function (response) { 
                    if(response.data.state == 'success') {
                        vm.displayModal = false
                        vm.users = vm.users.filter(e => e.user_id != response.data.id )
                    }else {
                        if(response.data.authError) {
                            store.commit('logout')
                            vm.$router.push('/login')
                        }else {
                            vm.errorTxt = 'An error has occured: ' + response.data.retour
                        }
                    }
                })
                .catch((err) => {
                    this.errorTxt = 'An error has occured: ' +  err
                });
        }
    }, 
    computed : {
        usersList() {
            if(this.search && this.search.length > 0) {
                return this.users.filter(e => e.email.toLowerCase().includes(this.search.toLowerCase()))
            }else { 
                return this.users
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    h1 {
            text-align: center;
        }

    .table-bar {
        margin-bottom:10px;
    }

    .delete-btn {
        background: url('./../../assets/images/trash-orange.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
    }

    .delete-btn:hover {
        background: url('./../../assets/images/trash-blue.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
    }

    .btn-search {
        color:$secondaryColor;
        width:250px;
        padding:8px 10px;
        border:1px solid $secondaryColor;
        border-radius:$radius;
        outline:none;
    }
</style>