<template>
    <div class="modal">
        <div class="modal-close" @click="closeModal"></div>
        <div class="modal-container">
            <div class="modal-text" v-html="contentModal"></div>            

            <div class="modal-btn-confirm" v-if="type != 1">
                <div class="btn btn-cancel" @click="closeModal">Annuler</div>
                <div class="btn btn-confirm" @click="confirmModal">Confirmer</div>
            </div>
            <div class="modal-btn-confirm" v-else>
                <div class="btn btn-confirm full" @click="confirmModal">Ok</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Modal',
    props : [ 'displayModal', 'contentModal', 'id', 'type'],
    methods : {
        closeModal() {
            this.$emit('hide')
        },
        confirmModal() {
            let params = {
                id : this.id,
            }
            this.$emit('confirm', params)
        }
    }
}
</script>

<style lang="scss" scoped>
    .modal {
        background:rgba(0,0,0, 0.5);
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-close {
        position: absolute;
        top:15px;
        right:15px;
        height:20px;
        width:20px;
        background: url('./../assets/images/icon-close.png') ;
        background-position: center;
        background-size: 20px;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .modal-container {
        background: #fff;        
        border-radius:10px;
        width:90%;
        max-width: 700px;
    }

    .modal-btn {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .modal-text {
        text-align: center;
        padding:25px;
    }

    .modal-btn-confirm {
        margin-top:40px;
        display: flex;
    }

    .modal-btn-confirm .btn {
        width:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height:50px;        
        cursor: pointer;
    }

    .btn-confirm {
        background: $mainColor;
        color:#fff;
        border:1px solid $mainColor;
        border-bottom-right-radius: 10px;
    }

    .btn-confirm.full {
        width:100%;
        border-bottom-left-radius: 10px;
    }

    .btn-confirm:hover {
        border:1px solid $mainColor;
        color:$mainColor; 
        background: #fff;
    }

    .btn-cancel {
        background: #fff;
        color:$secondaryColor;
        border:1px solid #fff;
        border-bottom-left-radius: 10px;
    }

    .btn-cancel:hover {
        border:1px solid $mainColor;
        color:$mainColor; 
    }
</style>